.home-page {
  video {
  height: 1100px;
  width: 100%;
  object-fit: cover;
  margin-bottom: -80px;
  margin-top: -50px;
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #ffffff;
    font-size: 50px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    letter-spacing: 1px;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #534717;
      font-size: 25px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6;
    }

    &::after {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #4c4014;
      font-size: 25px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
  }

  h2 {
    color: #ffffff;
    margin-top: 20px;
    font-weight: 600;
    font-size: 13px;
    font-family: sans-serif;
    letter-spacing: 1px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffffff;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffffff;
      color: #333;
    }
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }

  .home-page,
  .text-zone {
    width: 100% !important;
    transform: none;
    // padding: 2px;
    top: 50px;
    bottom: -150px;
    box-sizing: border-box;
    position: initial;
  }

  .flat-button {
    float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }

  .smartVid {
    position: relative;
    box-sizing: border-box;
    height: 940px;
    width: 1400px;
  }

  .logo-container svg {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
  }
}
