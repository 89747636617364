.container.skills-page {
  position: relative;
  margin: 0 10% !important;
  top: 0 !important;
  left: -150px !important;
  display: block;
  clear: both;
  width: 80%;
  height: auto;
}

.canvas-container {
  height: 100%;
  width: 100%;
  margin-left: 50%;
  position: absolute;
  padding-top: 5%;
}

.text-spinner {
  font-size: 50px;
}

@media screen and (max-width: 1200px) {
  .container.skills-page {
    // padding: 20px;
    box-sizing: border-box;
    display: block;
    position: initial;

    .text-zone {
      left: auto;
      right: auto;
      width: 100%;
      bottom: auto;
      top: auto;
    }

    h1.page-title {
      margin-top: -30px;
      font-size: 38px;
    }

    .canvas-container {
      top: 160px;
      left: -270px;
      right: auto;
      bottom: auto;
      width: 55rem;
    }
  }
}
