.navbar {
  background: #ffffff;
  width: 65px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .nav-logo {
    display: block;

    img {
      display: block;
      margin: 8px auto;
      width: 49px;
      height: auto;

      &.sub-logo {
        width: 58px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 218px;
    top: 50%;
    margin-top: -199px;
    width: 100%;

    a {
      font-size: 18px;
      color: #4d4d4e;
      display: block;
      line-height: 60px;
      height: 63px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #534717;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 8px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }
    &:first-child {
      &:after {
        content: 'HOME';
      }
    }
    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.skills-link {
      &:after {
        content: 'SKILLS';
      }
    }

    a.active {
      svg {
        color: #534717;
      }
    }

    .para1 {
      color: #000000;
      font-weight: bolder;
    }
  }

  ul {
    position: absolute;
    bottom: 109px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 5px 0;
        display: block;
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .navbar {
    // background: transparent;
    position: initial;
    height: auto;
    margin-top: -10px;
    min-height: auto;
    margin-bottom: 35px;
    padding-right: 1200px;

    ul,
    nav {
      display: none;
    }

    nav {
      width: 100%;
      height: 100%;
      background: #fff;
      top: 0;
      left: 0;
      margin: -10px;
      padding-right: 20px;
      margin-left: -20px;
      margin-right: 20px;
      z-index: 2;
      position: absolute;

      &.nav-items {
        display: flex;
        // transition: all 3s ease-in-out;
        transition: margin-right 2s 0.5s;
        opacity: 1;
        top: 85px;
        height: 50px;
        left: 0;
        text-align: center;
        z-index: 2;
        justify-content: space-around;
        font-size: 8px;

        .about-link {
          &:after {
            font-size: 0px;
          }
        }
        .portfolio-link {
          &:after {
            font-size: 0px;
          }
        }
        .skills-link {
          &:after {
            font-size: 0px;
          }
        }
        .contact-link {
          &:after {
            font-size: 0px;
          }
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: absolute;
        width: auto;
        margin-left: 10px;
      }
    }

    a svg {
      opacity: 1 !important;
    }

    .hamburger-icon,
    .close-icon {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}
